.approval__progress {
  padding: 2.4rem 1.6rem;
  background-color: $white;
  h3 {
    font-size: 1.4rem;
  }
  ol.approval__progressList {
    margin-top: 1.85rem;
    padding-bottom: 15px;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    li {
      width: 120px;
      flex-shrink: 0;
      div {
        padding: 3.5rem 2.2rem 0 0;
        background-repeat: no-repeat;
      }
      div.did {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='122' height='24' viewBox='0 0 122 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='22' y='10.5' width='100' height='3' rx='1.5' fill='%23888888'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.3063 9.81398C18.7183 9.36758 18.6905 8.67165 18.2441 8.25959C17.7977 7.84752 17.1018 7.87536 16.6897 8.32176L11.0214 14.4624L7.56428 11.4375C7.10708 11.0374 6.41214 11.0837 6.01209 11.5409C5.61204 11.9981 5.65837 12.6931 6.11557 13.0931L10.3788 16.8234C10.8274 17.216 11.5071 17.1797 11.9114 16.7417L18.3063 9.81398Z' fill='%23888888'/%3E%3C/svg%3E%0A");
      }
      div.do {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='122' height='24' viewBox='0 0 122 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='22' y='10.5' width='100' height='3' rx='1.5' fill='%23151515'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.3063 9.81398C18.7183 9.36758 18.6905 8.67165 18.2441 8.25959C17.7977 7.84752 17.1018 7.87536 16.6897 8.32176L11.0214 14.4624L7.56428 11.4375C7.10708 11.0374 6.41214 11.0837 6.01209 11.5409C5.61204 11.9981 5.65837 12.6931 6.11557 13.0931L10.3788 16.8234C10.8274 17.216 11.5071 17.1797 11.9114 16.7417L18.3063 9.81398Z' fill='%23151515'/%3E%3C/svg%3E%0A");
      }
      div.will {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='122' height='24' viewBox='0 0 122 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='22' y='10.5' width='100' height='3' rx='1.5' fill='%23DBDBDB'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.3063 9.81398C18.7183 9.36758 18.6905 8.67165 18.2441 8.25959C17.7977 7.84752 17.1018 7.87536 16.6897 8.32176L11.0214 14.4624L7.56428 11.4375C7.10708 11.0374 6.41214 11.0837 6.01209 11.5409C5.61204 11.9981 5.65837 12.6931 6.11557 13.0931L10.3788 16.8234C10.8274 17.216 11.5071 17.1797 11.9114 16.7417L18.3063 9.81398Z' fill='%23DBDBDB'/%3E%3C/svg%3E%0A");
      }
    }
    li:first-child {
      padding-left: 0;
    }
  }
}

.approval__order {
  padding: 2.4rem 1.6rem;
  background-color: $white;
  h3 {
    font-size: 1.4rem;
  }
  ol.approval__orderList {
    margin-top: 1.85rem;
    padding-bottom: 15px;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    li {
      width: 148px;
      border: 1px solid #e3e3e3;
      border-radius: 8px;
      padding: 18px 16px;
      margin-left: 8px;
      flex-shrink: 0;
      overflow: hidden;
      div {
        span {
          display: block;
          &.step {
            display: inline-block;
            padding: 2px 9.5px;
            font-size: 12px;
            background-color: rgba(0, 169, 255, 0.1);
            color: #00a9ff;
            font-weight: 600;
            // 승인
            &.approval {
              background-color: rgba(0, 169, 255, 0.1);
              color: #00a9ff;
            }
            //합의
            &.agreement {
              background-color: rgba(0, 213, 162, 0.1);
              color: #00d5a2;
            }
            // 진행중
            &.proceed {
              background-color: rgba(158, 95, 255, 0.1);
              color: #9e5fff;
            }
            // 반려
            &.reject {
              background-color: rgba(255, 100, 100, 0.1);
              color: #ff6464;
            }
            // 대기
            &.waiting {
              background-color: #f4f4f4;
              color: #666666;
            }
            // 기각
            &.dismiss {
              background-color: #ffb8401a;
              color: #ffb840;
            }
          }
          &.status {
            margin-top: 8px;
          }
          &.name {
            min-height: 40px;
            margin: 2px 0 12px 0;
            font-weight: $font-weight-semibold;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          &.date {
            font-size: 12px;
            color: #888;
          }
        }
      }
    }
    li:first-child {
      margin-left: 0;
    }
  }
}
