.inputView {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  input + input,
  input + button {
    margin-left: 0.5rem;
  }
}
.inputWrap {
  width: 100%;
  position: relative;
}

.text__input,
.formbuilder-text .form-control,
.text__search,
.text__textarea,
.formbuilder-textarea .form-control {
  width: 100%;
  height: 4.2rem;
  background-color: $white;
  line-height: 3.2rem;
  padding: 0 1rem;
  border: 0.1rem solid $gray-35;
  border-radius: 0.4rem;
  color: $black;
  outline: none;
  font-size: 1.4rem;
  background-color: $white;
  vertical-align: middle;
  font-weight: $font-weight-regular;
  &::placeholder {
    color: $gray-120;
  }
  &:hover,
  &:focus,
  &.is-hover {
    border-color: $gray-260;
    box-shadow: none;
  }
  &.is-error {
    padding-right: 3rem;
    border-color: $point-1;
    color: $point-1;

    ~ .error-text {
      color: $point-1;
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 13.635C10.0899 13.635 13 10.7249 13 7.13501C13 3.54516 10.0899 0.63501 6.5 0.63501C2.91015 0.63501 0 3.54516 0 7.13501C0 10.7249 2.91015 13.635 6.5 13.635Z' fill='%23BD0000'/%3E%3Cpath d='M6.5 4.13501V8.13501' stroke='white' stroke-width='1.5'/%3E%3Cpath d='M6.5 9.13501V10.635' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E%0A");
      background-position: 0 8px;
      background-repeat: no-repeat;
      font-size: 13px;
      padding-left: 18px;
      padding-top: 6px;
      user-select: none;
    }
  }
  &[disabled],
  &.is-disabled {
    background-color: $gray-15;
    border-color: $gray-35;
  }
  &[readonly] {
    background-color: $gray-15;
    border-color: $gray-35;
    color: $black;
  }
}

.text__search {
  background-position: calc(100% - 0.4rem) center;
  background-repeat: no-repeat;
  padding-right: 3rem;
  &:hover,
  &:focus,
  &.is-hover {
    + .btn__search {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M14.0909 14.3409C15.8482 12.5836 15.8482 9.73431 14.0909 7.97696C12.3335 6.2196 9.48426 6.2196 7.7269 7.97696C5.96954 9.73431 5.96954 12.5836 7.7269 14.3409C9.48426 16.0983 12.3335 16.0983 14.0909 14.3409Z' stroke='black' stroke-width='2'/%3E%3Cpath d='M13.7368 13.9871L17.9795 18.2297' stroke='black' stroke-width='2'/%3E%3C/svg%3E");
    }
  }
  &.is-error {
    background-image: none;
    + .btn__search {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M14.0909 14.3409C15.8482 12.5836 15.8482 9.73431 14.0909 7.97696C12.3335 6.2196 9.48426 6.2196 7.7269 7.97696C5.96954 9.73431 5.96954 12.5836 7.7269 14.3409C9.48426 16.0983 12.3335 16.0983 14.0909 14.3409Z' stroke='%23808080' stroke-width='2'/%3E%3Cpath d='M13.7368 13.9871L17.9795 18.2297' stroke='%23808080' stroke-width='2'/%3E%3C/svg%3E");
    }
  }
  &[disabled],
  &.is-disabled,
  &[readonly] {
    + .btn__search {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M14.0909 14.3409C15.8482 12.5836 15.8482 9.73431 14.0909 7.97696C12.3335 6.2196 9.48426 6.2196 7.7269 7.97696C5.96954 9.73431 5.96954 12.5836 7.7269 14.3409C9.48426 16.0983 12.3335 16.0983 14.0909 14.3409Z' stroke='%23DBDBDB' stroke-width='2'/%3E%3Cpath d='M13.7368 13.9871L17.9795 18.2297' stroke='%23DBDBDB' stroke-width='2'/%3E%3C/svg%3E");
    }
  }
}
.btn__search {
  position: absolute;
  right: 0.4rem;
  top: 0.4rem;
  width: 2.4rem;
  height: 2.4rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M14.0909 14.3409C15.8482 12.5836 15.8482 9.73431 14.0909 7.97696C12.3335 6.2196 9.48426 6.2196 7.7269 7.97696C5.96954 9.73431 5.96954 12.5836 7.7269 14.3409C9.48426 16.0983 12.3335 16.0983 14.0909 14.3409Z' stroke='%23808080' stroke-width='2'/%3E%3Cpath d='M13.7368 13.9871L17.9795 18.2297' stroke='%23808080' stroke-width='2'/%3E%3C/svg%3E");
  font-size: 0;
  content: "";
  &:hover,
  &:focus {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M14.0909 14.3409C15.8482 12.5836 15.8482 9.73431 14.0909 7.97696C12.3335 6.2196 9.48426 6.2196 7.7269 7.97696C5.96954 9.73431 5.96954 12.5836 7.7269 14.3409C9.48426 16.0983 12.3335 16.0983 14.0909 14.3409Z' stroke='black' stroke-width='2'/%3E%3Cpath d='M13.7368 13.9871L17.9795 18.2297' stroke='black' stroke-width='2'/%3E%3C/svg%3E");
  }
}

.text__textarea,
.formbuilder-textarea .form-control {
  min-height: 7rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  line-height: 2rem;
  resize: vertical;
  &.is-error {
    background-position: calc(100% - 0.4rem) calc(100% - 0.4rem);
  }
  &[disabled],
  &.is-disabled,
  &[readonly] {
    background-color: $gray-15;
    border-color: $gray-35;
  }
}

// ====== label =====
.label__input {
  white-space: nowrap;
  color: $white;
}

.is-reverse {
  flex-direction: row-reverse;
  text-align: right;
}

.formbuilder-text-label,
.formbuilder-textarea-label {
  display: block;
  color: $primary;
  font-weight: $font-weight-semibold;
  font-size: 1.4rem;
  margin-bottom: 0.6rem;
  .formbuilder-required {
    margin-left: 0.5rem;
    color: $point-1;
  }
}
