.chart__block {
  padding: 80px 0 24px 0;
  display: flex;
  background-color: $blue-100;
  overflow-y: hidden;
  overflow-x: scroll;

  //  /* Swiper Version
  &.swiper-initialized {
    overflow: hidden;
    margin-top: 60px;
    padding: 24px 0;

    swiper-slide {
      min-width: 300px;
      scroll-snap-align: unset;

      &:first-child {
        margin-left: 16px;
      }

      &:nth-last-child(2) {
        margin-right: 16px;
      }
    }

    .chart__card {
      min-width: 300px;
      width: inherit;
      margin-left: 0;
      margin-right: 0;
    }
  }
  // */

  .chart__card {
    width: 300px;
    height: 280px;
    background-color: $white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    padding: 24px 32px;
    position: relative;
    flex-shrink: 0;

    &:not(:last-child) {
      margin-left: 16px;
    }

    .chart__title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
    }

    .chart__center {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      left: calc(50% - 20px);
      margin-top: 68px;
      width: 45px;

      > :first-child {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        color: #ffb840;
      }

      > :nth-child(2) {
        font-size: 13px;
        line-height: 18px;
        color: $gray-260;
      }
    }

    .doughnut-chart {
      height: 132px;
    }

    .chart__card-bottom {
      display: flex;
      justify-content: space-around;
      padding-top: 10px;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;

        &:nth-child(2) {
          margin-left: -20px;
        }

        > span:first-child {
          font-size: 20px;
          font-weight: 600;
        }

        > span:nth-child(2) {
          font-size: 13x;
          line-height: 18px;
        }
      }
    }
  }
}

.white__card {
  background-color: $white;
  border-radius: 12px;
  padding: 20px 16px;

  &:not(:first-child) {
    margin-top: 16px;
  }

  .white__card-title {
    padding-bottom: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: block;
  }

  .white__card-content {
    display: block;
    font-size: 14px;
    line-height: 20px;
  }

  table + .white__card-item {
    margin-top: 6px;
  }

  .white__card-item {
    display: flex;
    flex-wrap: wrap;
    &:nth-child(2) {
      margin-top: 6px;
    }
    > span:first-child {
      width: 96px;
      min-width: 96px;
      color: $gray-80;
      display: inline-block;
      padding: 6px 8px 6px 0px;
    }
    > :nth-child(2) {
      padding: 4px 10px 4px 10px;
      color: $black;
      font-weight: 400;
      overflow: hidden;
      width: 100%;

      &:not(:has(.quill)) {
        flex: 1;
      }

      &:has(.quill) {
        padding-left: 0;
      }
    }
  }

  .btn {
    width: 100%;
    height: 42px;
    margin-top: 24px;
    font-size: 15px;
    color: #333333;
    border: 1px solid #cccccc;
    font-weight: 400;
  }
}

.request__card {

  & + .request__card {
    padding-top: 0;
  }
  padding: 24px 16px 0 16px;
  background-color: $white;
  display: flex;
  flex-direction: column;

  .request__card-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    margin-top: 12px;
  }

  .request__card-content {
    font-size: 16px;
    padding-top: 10px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    margin-bottom: 26px;
  }

  hr + .request__card-item {
    display: flex;
    padding-top: 14px;
  }

  .request__card-item {
    padding-top: 12px;
    display: flex;

    &:has(.inputView) {
      flex-direction: column;
    }

    > span:first-child,
    &.attatch__file > span {
      width: 96px;
      min-width: 96px;
      color: $gray-80;
      display: inline-block;
      margin-right: 18px;
    }

    > span:nth-child(2) {
      width: calc(100% - 114px);
      display: inline-block;
    }

    &.attatch__file {
      flex-wrap: wrap;

      .attatch__file-content {
        width: calc(100% - 114px);
        display: flex;
        flex-direction: column;

        span {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          color: #000;
          display: -webkit-box;
          max-height: 53px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          width: 100%;
          word-break: break-all;
          padding-right: 8px;
        }

        &:not(:nth-child(2)) {
          padding-top: 10px;
        }
      }
    }

    button {
      height: 32px;
      font-weight: 400;
      margin-top: -4px;
      font-size: 15px;
      color: #333333;
      border: 1px solid $gray-120;
      float: right;
      min-width: fit-content;
      margin-top: 5px;
    }
  }

  hr {
    border-color: #d0d0d0;
    margin-top: 14px;
  }

  > :last-child {
    padding-bottom: 30px;
  }
}

.search__card {
  background-color: $blue-100;
  padding: 32px 16px;

  .btn.is-primary {
    width: 100%;
    height: 42px;
    margin-top: 16px;
    font-size: 15px;
  }

  .btn.is-secondary {
    width: 100%;
    height: 46px;
    margin-top: 8px;
    margin-left: 0px;
    font-size: 15px;
    font-weight: 400;
    color: $gray-280;
    border: unset;
  }
}
