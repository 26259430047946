.code__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;

  :not(.code__state) {
    margin-top: 2px;
  }

  .code__icon {
    background-repeat: no-repeat;
    min-width: 20px;
    width: 20px;
    height: 20px;
  }

  .code__text {
    padding-left: 0.5rem;
    padding-right: 0.8rem;
    word-break: keep-all;
  }

  .simple {
    .code__text {
      color: #888888;
    }
    .code__icon {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='3' fill='%23888888'/%3E%3Cpath d='M9.55556 8.84444V6.71111C9.55556 6.31822 9.23733 6 8.84444 6H6.71111C6.31822 6 6 6.31822 6 6.71111V8.84444C6 9.23733 6.31822 9.55556 6.71111 9.55556H8.84444C9.23733 9.55556 9.55556 9.23733 9.55556 8.84444Z' fill='white'/%3E%3Cpath d='M11.1554 9.55556H13.2888C13.6817 9.55556 13.9999 9.23733 13.9999 8.84444V6.71111C13.9999 6.31822 13.6817 6 13.2888 6H11.1554C10.7626 6 10.4443 6.31822 10.4443 6.71111V8.84444C10.4443 9.23733 10.7626 9.55556 11.1554 9.55556Z' fill='white'/%3E%3Cpath d='M8.84444 10.4445H6.71111C6.31822 10.4445 6 10.7627 6 11.1556V13.2889C6 13.6818 6.31822 14 6.71111 14H8.84444C9.23733 14 9.55556 13.6818 9.55556 13.2889V11.1556C9.55556 10.7627 9.23733 10.4445 8.84444 10.4445Z' fill='white'/%3E%3Cpath d='M10.4443 11.1556V13.2889C10.4443 13.6818 10.7626 14 11.1554 14H13.2888C13.6817 14 13.9999 13.6818 13.9999 13.2889V11.1556C13.9999 10.7627 13.6817 10.4445 13.2888 10.4445H11.1554C10.7626 10.4445 10.4443 10.7627 10.4443 11.1556Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }

  .change {
    .code__text {
      color: $point-3;
    }
    .code__icon {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='3' fill='%23359DFF'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.96967 5.46967C10.2626 5.17678 10.7374 5.17678 11.0303 5.46967L14.3107 8.75H6C5.58579 8.75 5.25 8.41421 5.25 8C5.25 7.58579 5.58579 7.25 6 7.25H10.6893L9.96967 6.53033C9.67678 6.23744 9.67678 5.76256 9.96967 5.46967Z' fill='%23FFFEFE'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.0303 14.5303C9.73744 14.8232 9.26256 14.8232 8.96967 14.5303L5.68934 11.25L14 11.25C14.4142 11.25 14.75 11.5858 14.75 12C14.75 12.4142 14.4142 12.75 14 12.75L9.31066 12.75L10.0303 13.4697C10.3232 13.7626 10.3232 14.2374 10.0303 14.5303Z' fill='%23FFFEFE'/%3E%3C/svg%3E%0A");
    }
  }

  .problem {
    .code__text {
      color: #00d5a2;
    }
    .code__icon {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='3' fill='%2300D5A2'/%3E%3Cpath d='M8.77492 5H11.2389V11.566H8.77492V5ZM10.0069 14.996C9.63358 14.996 9.32092 14.8747 9.06892 14.632C8.81692 14.38 8.69092 14.072 8.69092 13.708C8.69092 13.3347 8.81692 13.0267 9.06892 12.784C9.32092 12.5413 9.63358 12.42 10.0069 12.42C10.3709 12.42 10.6789 12.546 10.9309 12.798C11.1829 13.0407 11.3089 13.344 11.3089 13.708C11.3089 14.072 11.1829 14.38 10.9309 14.632C10.6789 14.8747 10.3709 14.996 10.0069 14.996Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }

  .error {
    .code__text {
      color: #ff8730;
    }
    .code__icon {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='3' fill='%23FFB840'/%3E%3Cpath d='M7 7L13 13' stroke='%23FFFEFE' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M13 7L7 13' stroke='%23FFFEFE' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    }
  }

  .deployment {
    .code__text {
      color: #9e5fff;
    }
    .code__icon {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='3' fill='%239E5FFF'/%3E%3Cpath d='M13.9815 10.7271L13.7998 10.6186C13.5836 10.4896 13.4544 10.2582 13.4544 10C13.4544 9.74179 13.5836 9.51036 13.7998 9.38143L13.9815 9.27286C14.4776 8.97643 14.6472 8.32214 14.3608 7.80929L14.0154 7.19071C13.7297 6.67893 13.0945 6.50321 12.5998 6.79857L12.4181 6.90679C12.2019 7.03607 11.9432 7.03607 11.7273 6.90679C11.5111 6.7775 11.3819 6.54643 11.3819 6.28821V6.07143C11.3819 5.48071 10.9169 5 10.3456 5H9.65475C9.08342 5 8.61847 5.48071 8.61847 6.07143V6.28857C8.61847 6.54679 8.48929 6.77786 8.27305 6.90714C8.05681 7.03607 7.79844 7.03643 7.5822 6.90714L7.40051 6.79857C6.90586 6.50321 6.27062 6.67893 5.98461 7.19071L5.63919 7.80929C5.35283 8.32214 5.52243 8.97679 6.01846 9.27286L6.2005 9.38143C6.41674 9.51036 6.54593 9.74179 6.54593 10C6.54593 10.2582 6.41674 10.4896 6.2005 10.6186L6.01881 10.7271C5.52278 11.0232 5.35317 11.6779 5.63953 12.1907L5.98496 12.8093C6.27097 13.3211 6.9062 13.4968 7.40051 13.2014L7.5822 13.0932C7.79844 12.9636 8.05681 12.9643 8.27305 13.0932C8.48929 13.2225 8.61847 13.4536 8.61847 13.7118V13.9286C8.61847 14.5193 9.08342 15 9.65475 15H10.3456C10.9169 15 11.3819 14.5193 11.3819 13.9286V13.7114C11.3819 13.4532 11.5111 13.2221 11.7273 13.0929C11.9432 12.9639 12.2019 12.9636 12.4181 13.0929L12.5998 13.2014C13.0945 13.4964 13.7297 13.3207 14.0154 12.8093L14.3608 12.1907C14.6472 11.6779 14.4776 11.0232 13.9815 10.7271ZM10.0002 11.7857C9.04784 11.7857 8.27305 10.9846 8.27305 10C8.27305 9.01536 9.04784 8.21429 10.0002 8.21429C10.9525 8.21429 11.7273 9.01536 11.7273 10C11.7273 10.9846 10.9525 11.7857 10.0002 11.7857Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }

  .code__number {
    color: #888888;
    margin-right: 4px;
  }

  .code__state {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    gap: 10px;
    min-width: 69px;
    max-width: 100px;
    height: 28px;
    background: $gray-15;
    border-radius: 3px;
    font-size: 14px;
    float: right;

    &.success,
    &.progress {
      color: #8e8e8e;
    }
    &:not(.success, .progress) {
      color: #151515;
    }

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
