//tooltips
.tooltip {
  display: inline-flex;
  position: relative;
  &.is-light {
    .tooltip__item {
    }
    .tooltip__content {
      background: rgba(0, 0, 0, 0.8);
      background-color: $white;
      color: $black;
      &::before {
        border-bottom: 0.5rem solid $white;
      }
    }
  }
  .tooltip__content {
    display: none;
    position: absolute;
    top: 3rem;
    z-index: 1;
    min-width: 25rem;
    padding: 1rem 1.5rem;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 0.5rem;
    font-size: 1.3rem;
    line-height: 1.6rem;
    word-break: break-all;
    color: $white;
    box-shadow: 0px 0px 1.6rem rgba(0, 0, 0, 0.15);
    text-align: left;
    &:before {
      display: block;
      position: absolute;
      top: -1rem;
      border-bottom: 0.5rem solid rgba(0, 0, 0, 0.8);
      border-top: 0.5rem solid transparent;
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      content: "";
    }
    &.is-rightBottom {
      left: -1.7rem;
      &:before {
        left: 2rem;
      }
    }
    &.is-leftBottom {
      right: -1.9rem;
      &:before {
        right: 2rem;
      }
    }
    &.is-centerBottom {
      left: 50%;
      transform: translateX(-50%);
      &:before {
        top: -0.2rem;
        left: 50%;
        transform: rotate(45deg) translateX(-50%);
      }
    }
  }
  button {
    overflow: visible;
    color: $black;
    &:hover {
      .tooltip__content {
        display: block;
      }
    }
    .tooltip__item {
      display: inline-block;
      position: relative;
      width: 2rem;
      height: 2rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Ccircle cx='10' cy='10' r='10' fill='%23151515'/%3E%3Cpath d='M10.6927 12.1597V11.9921C10.6979 10.9607 11.0691 10.4791 11.8951 9.98168C12.8727 9.39529 13.5 8.62042 13.5 7.40052C13.5 5.5733 12.0414 4.5 9.97648 4.5C8.08925 4.5 6.54182 5.5 6.5 7.57853H8.60157C8.63816 6.71466 9.27595 6.25393 9.96079 6.25393C10.6822 6.25393 11.2573 6.7356 11.2573 7.46859C11.2573 8.15969 10.7659 8.62042 10.1229 9.0288C9.24459 9.58377 8.74795 10.1492 8.73749 11.9921V12.1597H10.6927ZM9.75691 15.5C10.4208 15.5 10.9959 14.945 11.0011 14.2592C10.9959 13.5785 10.4208 13.0236 9.75691 13.0236C9.07207 13.0236 8.50747 13.5785 8.51792 14.2592C8.50747 14.945 9.07207 15.5 9.75691 15.5Z' fill='white'/%3E%3C/svg%3E");
      vertical-align: middle;
    }
  }
}
