//checkbox
.checkGroup,
.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  min-width: 50px;

  &:not(:last-child) {
    margin-right: 1.6rem;
  }

  .formbuilder-checkbox {
    flex: 1;
    min-width: 80px;
  }
}

.check__label,
.formbuilder-checkbox label {
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
  font-size: 1.4rem;
  &.is-box {
    width: calc(50% - 0.4rem);
    margin-bottom: 0.8rem;
    &:nth-child(2n) {
      margin-left: 0.8rem;
    }
    .check__input {
      &:checked {
        + .check__style {
          border-color: $gray-90;
        }
        &:disabled + .check__style {
          border-color: $gray-35;
        }
      }
    }
    .check__style {
      min-width: 100%;
      height: 100%;
      padding: 1rem 1.6rem;
      padding-left: 4rem;
      border: 0.1rem solid $gray-35;
      border-radius: 0.4rem;
      &:before {
        top: 1.3rem;
        left: 1.6rem;
      }
    }
    &:hover {
      border-color: $gray-90;
    }
    &.is-disabled {
      border-color: $gray-35;
    }
    &.is-readonly {
      border-color: $gray-35;
      background-color: $gray-10;
      .check__input {
        border-color: $gray-80;
        + .check__style {
          &:before {
            border-color: $gray-80;
          }
        }
        &:checked {
          + .check__style {
            border-color: $gray-75;
            &:before {
              border-color: $gray-75;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M4 0.5H14C15.933 0.5 17.5 2.067 17.5 4V14C17.5 15.933 15.933 17.5 14 17.5H4C2.067 17.5 0.5 15.933 0.5 14V4C0.5 2.067 2.067 0.5 4 0.5Z' fill='%23909090' stroke='%23909090'/%3E%3Cpath d='M7.63406 12.1982C7.70537 12.2267 7.78058 12.2396 7.85729 12.2396C7.934 12.2396 8.00921 12.2267 8.08053 12.1982C8.15736 12.1675 8.2245 12.1177 8.28309 12.0591L13.4966 6.84559C13.6146 6.7276 13.6771 6.57999 13.6771 6.41302C13.6771 6.24605 13.6146 6.09844 13.4966 5.98045C13.3786 5.86246 13.231 5.8 13.0641 5.8C12.8971 5.8 12.7495 5.86246 12.6315 5.98045L7.85729 10.7547L5.83205 8.72941C5.71581 8.61318 5.57109 8.54896 5.40625 8.54896C5.24141 8.54896 5.09669 8.61318 4.98045 8.72941C4.86081 8.84906 4.8 8.99962 4.8 9.16875C4.8 9.33788 4.86081 9.48844 4.98045 9.60809L7.4315 12.0591C7.49008 12.1177 7.55722 12.1675 7.63406 12.1982Z' fill='white' stroke='white' stroke-width='0.4'/%3E%3C/svg%3E");
            }
          }
        }
      }
    }
  }

  @at-root .formbuilder-checkbox > input,
    .check__input {
    position: absolute;
    left: 0;
    z-index: -1;
    opacity: 0;
    appearance: auto;
    &:checked + .check__style,
    &:checked + label {
      &:before {
        content: "";
        width: 16px;
        height: 16px;
        border-color: $primary;
        background-color: $primary;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Crect x='0.5' y='0.5' width='15' height='15' rx='1.5' fill='%23151515' stroke='%23151515'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.55939 11.3641C6.65688 11.4031 6.75765 11.4198 6.8569 11.4198C6.95615 11.4198 7.05692 11.4031 7.15442 11.3641C7.26294 11.3207 7.35209 11.2528 7.42412 11.1808L12.6377 5.96721C12.7924 5.81248 12.8767 5.61431 12.8767 5.39322C12.8767 5.17214 12.7924 4.97396 12.6377 4.81923C12.4829 4.6645 12.2848 4.5802 12.0637 4.5802C11.8426 4.5802 11.6444 4.6645 11.4897 4.81923L6.8569 9.45202L4.97308 7.56819C4.82186 7.41698 4.62721 7.32916 4.40586 7.32916C4.18451 7.32916 3.98986 7.41698 3.83864 7.56819C3.6806 7.72623 3.59961 7.92805 3.59961 8.14895C3.59961 8.36986 3.6806 8.57167 3.83864 8.72971L6.28968 11.1808C6.36171 11.2528 6.45086 11.3207 6.55939 11.3641Z' fill='white'/%3E%3C/svg%3E");
      }
    }
  }

  &.is-disabled {
    pointer-events: none;
    color: $gray-120;
    .check__input {
      &:disabled {
        pointer-events: none;
      }
      &:disabled + .check__style {
        &:before {
          border-color: $gray-30;
          background-color: $gray-10;
          pointer-events: none;
        }
      }
      &:checked {
        &:disabled + .check__style {
          &:before {
            content: "";
            width: 16px;
            height: 16px;
            border-color: $gray-30;
            background-color: $gray-30;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Crect x='0.5' y='0.5' width='15' height='15' rx='1.5' fill='%23E9E9E9' stroke='%23E9E9E9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.55939 11.3641C6.65688 11.4031 6.75765 11.4198 6.8569 11.4198C6.95615 11.4198 7.05692 11.4031 7.15442 11.3641C7.26294 11.3207 7.35209 11.2528 7.42412 11.1808L12.6377 5.96721C12.7924 5.81248 12.8767 5.61431 12.8767 5.39322C12.8767 5.17214 12.7924 4.97396 12.6377 4.81923C12.4829 4.6645 12.2848 4.5802 12.0637 4.5802C11.8426 4.5802 11.6444 4.6645 11.4897 4.81923L6.8569 9.45202L4.97308 7.56819C4.82186 7.41698 4.62721 7.32916 4.40586 7.32916C4.18451 7.32916 3.98986 7.41698 3.83864 7.56819C3.6806 7.72623 3.59961 7.92805 3.59961 8.14895C3.59961 8.36986 3.6806 8.57167 3.83864 8.72971L6.28968 11.1808C6.36171 11.2528 6.45086 11.3207 6.55939 11.3641Z' fill='white'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }
  &.is-readonly {
    pointer-events: none;
    .check__input {
      pointer-events: none;
      + .check__style {
        &:before {
          border-color: $gray-30;
          background-color: $gray-10;
          pointer-events: none;
        }
      }
      &:checked {
        & + .check__style {
          &:before {
            content: "";
            width: 16px;
            height: 16px;
            border-color: $gray-30;
            background-color: $gray-30;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Crect x='0.5' y='0.5' width='15' height='15' rx='1.5' fill='%23E9E9E9' stroke='%23E9E9E9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.55939 11.3641C6.65688 11.4031 6.75765 11.4198 6.8569 11.4198C6.95615 11.4198 7.05692 11.4031 7.15442 11.3641C7.26294 11.3207 7.35209 11.2528 7.42412 11.1808L12.6377 5.96721C12.7924 5.81248 12.8767 5.61431 12.8767 5.39322C12.8767 5.17214 12.7924 4.97396 12.6377 4.81923C12.4829 4.6645 12.2848 4.5802 12.0637 4.5802C11.8426 4.5802 11.6444 4.6645 11.4897 4.81923L6.8569 9.45202L4.97308 7.56819C4.82186 7.41698 4.62721 7.32916 4.40586 7.32916C4.18451 7.32916 3.98986 7.41698 3.83864 7.56819C3.6806 7.72623 3.59961 7.92805 3.59961 8.14895C3.59961 8.36986 3.6806 8.57167 3.83864 8.72971L6.28968 11.1808C6.36171 11.2528 6.45086 11.3207 6.55939 11.3641Z' fill='white'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }

  @at-root .formbuilder-checkbox > label,
    .check__style {
    min-width: 1.6rem;
    min-height: 2rem;
    padding-left: 2.2rem;
    &::before {
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0.15rem;
      width: 1.6rem;
      height: 1.6rem;
      border: 0.1rem solid $gray-65;
      border-radius: 0.2rem;
      background-color: $white;
      background-repeat: no-repeat;
      background-position: center;
      content: "";
    }
    &:empty {
      padding-left: 1.6rem;
    }
  }
  &:hover .check__style,
  &:focus .check__style {
    &:before {
      border-color: $primary;
    }
  }
  &.is-reverse {
    .check__style {
      padding: 0 2.2rem 0 0;
      &::before {
        left: auto;
        right: 0;
      }
    }
  }
}

//radioBox
.radioGroup,
.radio-group {
  display: block;
  position: relative;
}

.radioGroup + .radioGroup {
  margin-left: 1.6rem;
}

.radio__input {
  position: absolute;
  z-index: -1;
  left: 0;
  opacity: 0;
  width: 1.6rem;
  height: 1.6rem;
  appearance: auto;
  &:checked + .radio__style {
    border-color: $primary;
    background-color: $primary;
    &:after {
      width: 0.8rem;
      height: 0.8rem;
    }
  }
}

.radio__label {
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;

  &:disabled,
  &.is-disabled {
    pointer-events: none;
    color: $gray-120;
    .radio__style {
      background-color: $gray-10;
      border-color: $gray-30;
      pointer-events: none;
      &:after {
        background-color: #f9f9f9;
        width: 0.8rem;
        height: 0.8rem;
      }
    }
    .radio__input {
      &:checked + .radio__style {
        background-color: $gray-30;
        &:after {
          width: 0.8rem;
          height: 0.8rem;
        }
      }
    }
  }

  &.is-readonly {
    pointer-events: none;
    .radio__style {
      background-color: $gray-10;
      border-color: $gray-30;
      pointer-events: none;
      &:after {
        background-color: #f9f9f9;
        width: 0.8rem;
        height: 0.8rem;
      }
    }
    .radio__input {
      &:checked + .radio__style {
        background-color: $gray-30;
        &:after {
          width: 0.8rem;
          height: 0.8rem;
        }
      }
    }
  }

  .radio__style {
    display: inline-block;
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
    border: 0.1rem solid $gray-65;
    border-radius: 50%;
    background: $white;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 0.6rem;
    transition: all 0.3s;
    cursor: pointer;
    &:after {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background: $white;
      content: "";
      transform: translate(-50%, -50%);
    }
  }
  &:hover .radio__style,
  &:focus .radio__style {
    border-color: $primary;
  }

  &.is-reverse {
    .radio__style {
      margin-left: 0.6rem;
      margin-right: 0rem;
    }
  }
}

// ====== label =====
.formbuilder-checkbox-group-label,
.formbuilder-radio-group-label {
  display: block;
  color: $primary;
  font-weight: $font-weight-semibold;
  font-size: 1.4rem;
  margin-bottom: 0.6rem;
  .formbuilder-required {
    margin-left: 0.5rem;
    color: $point-1;
  }
}

// ====== Form Builder Radio =====
.formbuilder-radio {
  input {
    position: absolute;
    z-index: -1;
    left: 0;
    opacity: 0;
    width: 1.6rem;
    height: 1.6rem;
    appearance: auto;
    &:checked + label {
      &::before {
        border-color: $primary;
        background-color: $primary;
      }
      &:after {
        width: 0.8rem;
        height: 0.8rem;
      }

      &[disabled] {
        color: $gray-120;
        &::before {
          background-color: $gray-30;
          border-color: $gray-30;
        }
      }
    }
  }

  label {
    display: inline-flex;
    position: relative;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    cursor: pointer;

    &::before {
      content: "";
      display: inline-block;
      position: relative;
      width: 1.6rem;
      height: 1.6rem;
      border: 0.1rem solid $gray-65;
      border-radius: 50%;
      background: $white;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 0.6rem;
      transition: all 0.3s;
      cursor: pointer;
    }

    &:after {
      display: block;
      position: absolute;
      left: 8px;
      top: 50%;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background: $white;
      content: "";
      transform: translate(-50%, -50%);
    }

    &[disabled] {
      color: $gray-120;
    }

    &[disabled],
    &[readonly] {
      pointer-events: none;
      &::before {
        border-color: $gray-30;
      }
    }

    &:hover::before,
    &:focus::before {
      border-color: $primary;
    }
  }
}
