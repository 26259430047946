.before-load {
  overflow: hidden;
}

.loader {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  .spinner {
    width: 6.6rem;
    height: 6.6rem;
    border-width: 0.6rem;
    border-style: solid;
    border-color: #151515;
    border-bottom-color: #e5e5e5;
    border-radius: 50%;
    animation: rotation 0.66s linear infinite;
  }

  &.is-sm {
    .spinner {
      width: 2rem;
      height: 2rem;
      border-width: 0.4rem;
    }
  }
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: $loading-index;

  .spinner {
    width: 6.6rem;
    height: 6.6rem;
    border: 0.6rem solid #151515;
    border-bottom-color: #e5e5e5;
    border-radius: 50%;
    animation: rotation 0.66s linear infinite;
  }

  .before-load & {
    opacity: 1;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*
.loading {
  @include flex-center();
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $loading-index;
  opacity: 0;
  transition: .5s;
  background-color: #fff;

  .before-load & {
    opacity: 1;
  }

  .loading__circle {
    width: 5.4rem;
    height: 5.4rem;
    animation: loading-spin 3s infinite;

    circle {
      stroke: #000;
      stroke-width: 4;
      stroke-dasharray: 157;
      stroke-dashoffset: 0;
      fill: transparent;
      animation: loading-circle-ani 1s infinite;
    }
  }
}

@keyframes loading-spin {
  100% { transform: rotate(360deg); }
}

@keyframes loading-circle-ani {
  0%   { stroke-dashoffset: 157; }
  75%  { stroke-dashoffset: -147; }
  100% { stroke-dashoffset: -157; }
}
*/
