.modal {
  display: none;
  vertical-align: middle;
  align-items: center;
  overflow: hidden;
  position: fixed;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  z-index: 9999;

  .modal__inner {
    position: relative;
    overflow-y: scroll;
    z-index: 1;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background: $white;
    &.is-fixed {
      padding-top: 5rem;
      .modal__header {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
      }
    }
  }
  .modal__header {
    width: 100%;
    min-height: 5rem;
    padding: 1.3rem 1.6rem;
    padding-right: 4rem;
    background-color: $primary;

    .modal__title {
      display: block;
      overflow: hidden;
      font-weight: $font-weight-regular;
      font-size: 1.8rem;
      line-height: 2.4rem;
      color: $white;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.is-long {
      .modal__title {
        white-space: normal;
      }
    }
    .modal__close {
      position: absolute;
      top: 1.3rem;
      right: 1.6rem;
      width: 2.4rem;
      height: 2.4rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.5303 5.53033C19.8232 5.23744 19.8232 4.76256 19.5303 4.46967C19.2374 4.17678 18.7626 4.17678 18.4697 4.46967L12 10.9393L5.53033 4.46967C5.23744 4.17678 4.76256 4.17678 4.46967 4.46967C4.17678 4.76256 4.17678 5.23744 4.46967 5.53033L10.9393 12L4.46967 18.4697C4.17678 18.7626 4.17678 19.2374 4.46967 19.5303C4.76256 19.8232 5.23744 19.8232 5.53033 19.5303L12 13.0607L18.4697 19.5303C18.7626 19.8232 19.2374 19.8232 19.5303 19.5303C19.8232 19.2374 19.8232 18.7626 19.5303 18.4697L13.0607 12L19.5303 5.53033Z' fill='white'/%3E%3C/svg%3E");
      font-size: 0;
    }
  }
  .modal__content {
    min-height: calc(100vh - 14rem);
  }
  .modal__footer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;
    width: 100%;
    height: 9rem;
    padding: 2rem 1.6rem;
    background-color: $white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    .btn {
      flex-grow: 1;
    }
  }
}

.toastAlert {
  position: fixed;
  left: 1.6rem;
  right: 1.6rem;
  bottom: 1.6rem;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 0.4rem;
  padding: 1rem 0;
  text-align: center;
  p {
    font-weight: $font-weight-midium;
    font-size: 1.4rem;
    line-height: 2rem;
    color: $white;
  }
}
