// Images - Path
$images: "/src/assets_mb/images";
// Path Ex.) background: url(#{$images}/svg/name.svg) no-repeat 0 0;

// Docs Colors
$white: #ffffff;
$black: #000000;
$primary: #151515;
$secondary: #476c97;
$docs-colors: (
  "white": $white,
  "black": $black,
  "primary": $primary,
  "secondary": $secondary,
);

// Gray Scale Colors
$gray-10: #f6f6f6;
$gray-15: #f4f4f4;
$gray-20: #f8f8f8;
$gray-30: #e9e9e9;
$gray-35: #e3e3e3;
$gray-40: #dadada;
$gray-50: #d0d0d0;
$gray-60: #c4c4c4;
$gray-65: #bcbcbc;
$gray-70: #9f9f9f;
$gray-75: #909090;
$gray-80: #8e8e8e;
$gray-85: #666666;
$gray-90: #535353;
$gray-100: #e1e1e1;
$gray-120: #dbdbdb;
$gray-140: #d4d4d4;
$gray-160: #cccccc;
$gray-200: #9a9a9a;
$gray-220: #808080;
$gray-240: #707070;
$gray-260: #666666;
$gray-280: #333333;
$grays: (
  "20": $gray-20,
  "40": $gray-40,
  "60": $gray-60,
  "65": $gray-65,
  "80": $gray-80,
  "100": $gray-100,
  "120": $gray-120,
  "140": $gray-140,
  "160": $gray-160,
  "200": $gray-200,
  "220": $gray-220,
  "240": $gray-240,
  "260": $gray-260,
  "280": $gray-280,
);

// Blue Scale Colors
$blue-100: #f2f5f6;
$blue-200: #eef2f5;
$blue-300: #99a8b5;
$blue-grays: (
  "100": $blue-100,
  "200": $blue-200,
  "300": $blue-300,
);

// Point Colors
$point-1: #bd0000;
$point-2: #f9b037;
$point-3: #359dff;
$point-colors: (
  "1": $point-1,
  "2": $point-2,
  "3": $point-3,
);

// Black(Opacity) Colors
$black-5: rgba(0, 0, 0, 0.05);
$black-10: rgba(0, 0, 0, 0.1);
$black-15: rgba(0, 0, 0, 0.15);
$black-20: rgba(0, 0, 0, 0.2);
$black-25: rgba(0, 0, 0, 0.25);
$black-30: rgba(0, 0, 0, 0.3);
$black-35: rgba(0, 0, 0, 0.35);
$black-40: rgba(0, 0, 0, 0.4);
$black-45: rgba(0, 0, 0, 0.45);
$black-50: rgba(0, 0, 0, 0.5);
$black-55: rgba(0, 0, 0, 0.55);
$black-60: rgba(0, 0, 0, 0.6);
$black-65: rgba(0, 0, 0, 0.65);
$black-70: rgba(0, 0, 0, 0.7);
$black-75: rgba(0, 0, 0, 0.75);
$black-80: rgba(0, 0, 0, 0.8);
$black-85: rgba(0, 0, 0, 0.85);
$black-90: rgba(0, 0, 0, 0.9);
$black-95: rgba(0, 0, 0, 0.95);
$black-colors: (
  "5": $black-5,
  "10": $black-10,
  "15": $black-15,
  "20": $black-20,
  "25": $black-25,
  "30": $black-30,
  "35": $black-35,
  "40": $black-40,
  "45": $black-45,
  "50": $black-50,
  "55": $black-55,
  "60": $black-60,
  "65": $black-65,
  "70": $black-70,
  "75": $black-75,
  "80": $black-80,
  "85": $black-85,
  "90": $black-90,
  "95": $black-95,
);

// White(Opacity) Colors
$white-5: rgba(255, 255, 255, 0.05);
$white-10: rgba(255, 255, 255, 0.1);
$white-15: rgba(255, 255, 255, 0.15);
$white-20: rgba(255, 255, 255, 0.2);
$white-25: rgba(255, 255, 255, 0.25);
$white-30: rgba(255, 255, 255, 0.3);
$white-35: rgba(255, 255, 255, 0.35);
$white-40: rgba(255, 255, 255, 0.4);
$white-45: rgba(255, 255, 255, 0.45);
$white-50: rgba(255, 255, 255, 0.5);
$white-55: rgba(255, 255, 255, 0.55);
$white-60: rgba(255, 255, 255, 0.6);
$white-65: rgba(255, 255, 255, 0.65);
$white-70: rgba(255, 255, 255, 0.7);
$white-75: rgba(255, 255, 255, 0.75);
$white-80: rgba(255, 255, 255, 0.8);
$white-85: rgba(255, 255, 255, 0.85);
$white-90: rgba(255, 255, 255, 0.9);
$white-95: rgba(255, 255, 255, 0.95);
$white-colors: (
  "5": $white-5,
  "10": $white-10,
  "15": $white-15,
  "20": $white-20,
  "25": $white-25,
  "30": $white-30,
  "35": $white-35,
  "40": $white-40,
  "45": $white-45,
  "50": $white-50,
  "55": $white-55,
  "60": $white-60,
  "65": $white-65,
  "70": $white-70,
  "75": $white-75,
  "80": $white-80,
  "85": $white-85,
  "90": $white-90,
  "95": $white-95,
);

// Document
$document-max-width: 768rem;
$document-min-width: 32rem;
$document-min-height: 100vh;
$document-font-family: "Pretendard", -apple-system, BlinkMacSystemFont,
  "Apple SD Gothic Neo", Roboto, "Malgun Gothic", "맑은 고딕", Dotum, "돋움",
  sans-serif;
$document-font-size: 1.4rem;
$document-font-weight: 400;
$document-font-color: #000;

// Z-index
$loading-index: 3000;
$modal-index: 2000;
$layout-index: 1000;

// Header
$header-height: 5rem;

// Font-weight
$font-weight-regular: 400;
$font-weight-midium: 500;
$font-weight-semibold: 600;
