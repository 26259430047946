// Document
// html,
// body,
// .wrapper {
//   min-height: 100vh;
// }
body {
  font-size: $document-font-size;
  font-weight: $document-font-weight;
  color: $document-font-color;
}
body,
button,
fieldset,
iframe,
input,
select,
textarea {
  font-family: $document-font-family;
}

// Wrapper
.wrapper {
  position: relative;
  min-width: $document-min-width;
  min-height: $document-min-height;
  &.main {
    .content {
      padding-top: 0;
    }
  }
}

// Container
.container {
  display: flex;
  min-width: $document-min-width;
  max-width: $document-max-width;
  margin: 0 auto;
}

// Content
.content {
  width: 100%;
  padding-top: 5rem;

  .content__title {
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: 0 60px;
    font-weight: $font-weight-midium;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: $white;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

// Content blocks
.content__blocks {
  width: 100%;
  padding: 2rem 1.6rem;
  // background: $white;

  &.is-header {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    z-index: 1;
  }
}
.is-bg-gray {
  background-color: #f6f6f6;
}
.content__bgLine {
  height: 1rem;
  background: #f2f2f2;
}

//  Content header
.content__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8888;
  width: 100%;
  height: 5rem;
  background-color: $primary;

  .content__homeBtn {
    position: absolute;
    left: 1.6rem;
    top: 1.3rem;
    z-index: 1;
    width: 2.4rem;
    height: 2.4rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M16 3L7 12L16 21' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    font-size: 0;
  }
}

.error__page {
  width: 100%;
  padding: 26rem 0;
  text-align: center;
  h3 {
    font-size: 1.6rem;
    font-weight: $font-weight-semibold;
    color: #bd0000;
  }
  h3::before {
    content: '';
    display: block;
    width: 4.8rem;
    height: 4.8rem;
    margin: 0 auto 1.6rem;
    background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='24' cy='24' r='22' fill='%23D9D9D9'/%3E%3Cpath d='M31.5 21V18.8C31.5 17.1198 31.5 16.2798 31.173 15.638C30.8854 15.0735 30.4265 14.6146 29.862 14.327C29.2202 14 28.3802 14 26.7 14H20.3C18.6198 14 17.7798 14 17.138 14.327C16.5735 14.6146 16.1146 15.0735 15.827 15.638C15.5 16.2798 15.5 17.1198 15.5 18.8V29.2C15.5 30.8802 15.5 31.7202 15.827 32.362C16.1146 32.9265 16.5735 33.3854 17.138 33.673C17.7798 34 18.6198 34 20.3 34H20.5M24.5 25L28.5 29M28.5 29L32.5 33M28.5 29L32.5 25M28.5 29L24.5 33' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  h3.error400::before {
    background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='24' cy='24' r='22' fill='%23D9D9D9'/%3E%3Cpath d='M31 17L17 31M17 17L31 31' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  h3.error401::before {
    background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='24' cy='24' r='22' fill='%23D9D9D9'/%3E%3Cpath d='M29.9375 21.6667V19.3333C29.9375 16.1117 27.2792 13.5 24 13.5C20.7208 13.5 18.0625 16.1117 18.0625 19.3333V21.6667M24 26.9167V29.25M20.2 34.5H27.8C29.7952 34.5 30.7928 34.5 31.5548 34.1185C32.2252 33.783 32.7702 33.2475 33.1117 32.589C33.5 31.8403 33.5 30.8602 33.5 28.9V27.2667C33.5 25.3065 33.5 24.3264 33.1117 23.5777C32.7702 22.9191 32.2252 22.3837 31.5548 22.0481C30.7928 21.6667 29.7952 21.6667 27.8 21.6667H20.2C18.2048 21.6667 17.2072 21.6667 16.4452 22.0481C15.7748 22.3837 15.2298 22.9191 14.8883 23.5777C14.5 24.3264 14.5 25.3065 14.5 27.2667V28.9C14.5 30.8602 14.5 31.8403 14.8883 32.589C15.2298 33.2475 15.7748 33.783 16.4452 34.1185C17.2072 34.5 18.2048 34.5 20.2 34.5Z' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  h3.error404::before {
    background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='24' cy='24' r='22' fill='%23D9D9D9'/%3E%3Cpath d='M34.5 34.5L30.1833 30.1833M20.2784 20.2783L23.2784 23.2783M23.2784 23.2783L26.2785 26.2784M23.2784 23.2783L20.2784 26.2784M23.2784 23.2783L26.2785 20.2783M33.0556 23.2778C33.0556 28.6779 28.6779 33.0556 23.2778 33.0556C17.8777 33.0556 13.5 28.6779 13.5 23.2778C13.5 17.8777 17.8777 13.5 23.2778 13.5C28.6779 13.5 33.0556 17.8777 33.0556 23.2778Z' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  h3.error500::before {
    background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='24' cy='24' r='22' fill='%23D9D9D9'/%3E%3Cpath d='M24 21.5026V25.5017M24 28.5008H24.0102M22.5834 15.3955L14.1689 29.5989C13.7022 30.3867 13.4688 30.7806 13.5033 31.1039C13.5334 31.3859 13.6846 31.6421 13.9192 31.8088C14.1883 32 14.654 32 15.5855 32H32.4145C33.346 32 33.8117 32 34.0808 31.8088C34.3154 31.6421 34.4666 31.3859 34.4967 31.1039C34.5312 30.7806 34.2978 30.3867 33.8311 29.5989L25.4166 15.3955C24.9516 14.6106 24.719 14.2181 24.4157 14.0862C24.151 13.9713 23.849 13.9713 23.5843 14.0862C23.281 14.2181 23.0484 14.6106 22.5834 15.3955Z' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  p {
    padding: 0.8rem 0 3.2rem 0;
    font-size: 1.6rem;
    color: #666666;
  }
}
