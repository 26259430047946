.tabs {
  .tab__listWrap {
    overflow: hidden;
    position: relative;
    &.is-open {
      &::before {
        position: absolute;
        width: 10rem;
        height: 8rem;
        right: 0;
        top: 0;
        z-index: 1;
        background: linear-gradient(
          270deg,
          #f6f6f6 60%,
          rgba(246, 246, 246, 0) 100%
        );
        content: "";
      }
      .tab__list {
        padding-right: 6rem;
      }
    }
    &.on {
      &::before {
        display: none;
      }
      .tab__list {
        flex-wrap: wrap;
      }
    }
    .is-btnOpen {
      position: absolute;
      top: 2rem;
      right: 1.4rem;
      z-index: 1;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      border: 0.1rem solid $gray-30;
      background-color: $white;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M4 7L9.64645 12.6464C9.84171 12.8417 10.1583 12.8417 10.3536 12.6464L16 7' stroke='black' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .tab__list {
    display: flex;
    overflow-x: scroll;
    // background: #f6f6f6;
    // padding: 2rem 1.4rem 1.2rem;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  }
  .tab__item {
    // margin-right: 0.4rem;
    // margin-bottom: 0.8rem;
    flex-shrink: 0;
    flex-grow: 1;
    &:first-child {
      margin-left: 0;
    }
    &.is-disabled {
      .tab__link {
        color: $gray-120;
        pointer-events: none;
      }
    }
    .tab__link {
      @include flex-center();
      background-color: $white;
      border-bottom: 0.1rem solid $gray-30;
      // border-radius: 2rem;
      height: 4rem;
      padding: 1rem 1.6rem;
      font-weight: $font-weight-midium;
      font-size: 1.4rem;
      line-height: 2rem;
      color: $gray-70;
      &:hover {
        // background-color: $primary;
        color: $primary;
      }
      &.is-active {
        position: relative;
        // background-color: $primary;
        border-bottom: 0.2rem solid $primary;
        color: $primary;
      }
    }
  }

  .tab__content {
    display: none;

    &.is-active {
      display: block;
    }
  }
}
