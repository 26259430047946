//form search
.formSearch {
  position: relative;
  .formSearch__content {
    .formSearch__item {
      .formSearch__title {
        display: block;
        position: relative;
        margin-bottom: 0.6rem;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #151515;
        &.is-essential {
          &:after {
            display: inline-block;
            width: 0.5rem;
            height: 0.5rem;
            margin-left: 0.5rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='5' viewBox='0 0 5 5' fill='none'%3E%3Cpath d='M1.98953 5H2.98429L2.90576 3.16583L4.50262 4.17085L5 3.34171L3.29843 2.51256L5 1.68342L4.50262 0.854271L2.90576 1.83417L2.98429 0H1.98953L2.09424 1.83417L0.471204 0.854271L0 1.68342L1.70157 2.51256L0 3.34171L0.471204 4.17085L2.09424 3.16583L1.98953 5Z' fill='%23BD0000'/%3E%3C/svg%3E");
            vertical-align: super;
            content: "";
          }
        }
      }
      .formSearch__form {
        flex-grow: 1;
        flex-basis: 18rem;
      }
      + .formSearch__item {
        margin-top: 0.8rem;
      }
    }
  }
  .formSearch__btn {
    display: flex;
    margin-top: 0.8rem;
  }
}
