.formSelect {
  &::-ms-expand {
    display: none;
  }

  .tui-select-box-input {
    height: 4.2rem;
    background: $white;
    border: 0.1rem solid $gray-35;
    border-radius: 0.4rem;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.49011 12C8.42418 12 8.35495 11.9871 8.28242 11.9614C8.20989 11.9356 8.14066 11.8906 8.07473 11.8263L4.17802 8.02317C4.05934 7.90734 4 7.76577 4 7.59846C4 7.43115 4.05934 7.28958 4.17802 7.17375C4.2967 7.05792 4.43516 7 4.59341 7C4.75165 7 4.89011 7.05792 5.00879 7.17375L8.49011 10.5714L11.9714 7.17375C12.0901 7.05792 12.2319 7 12.3967 7C12.5615 7 12.7033 7.05792 12.822 7.17375C12.9407 7.28958 13 7.42793 13 7.5888C13 7.74968 12.9407 7.88803 12.822 8.00386L8.90549 11.8263C8.83956 11.8906 8.77363 11.9356 8.70769 11.9614C8.64176 11.9871 8.56923 12 8.49011 12Z' fill='%238E8E8E'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: calc(100% - 0.5rem) center;
    &:hover,
    &:focus,
    &:active {
      outline: none;
      border-color: $gray-260;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.49011 12C8.42418 12 8.35495 11.9871 8.28242 11.9614C8.20989 11.9356 8.14066 11.8906 8.07473 11.8263L4.17802 8.02317C4.05934 7.90734 4 7.76577 4 7.59846C4 7.43115 4.05934 7.28958 4.17802 7.17375C4.2967 7.05792 4.43516 7 4.59341 7C4.75165 7 4.89011 7.05792 5.00879 7.17375L8.49011 10.5714L11.9714 7.17375C12.0901 7.05792 12.2319 7 12.3967 7C12.5615 7 12.7033 7.05792 12.822 7.17375C12.9407 7.28958 13 7.42793 13 7.5888C13 7.74968 12.9407 7.88803 12.822 8.00386L8.90549 11.8263C8.83956 11.8906 8.77363 11.9356 8.70769 11.9614C8.64176 11.9871 8.56923 12 8.49011 12Z' fill='%23151515'/%3E%3C/svg%3E%0A");
    }
    &.tui-select-box-open {
      border-color: $gray-260;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M8.50989 7C8.57582 7 8.64505 7.01287 8.71758 7.03861C8.79011 7.06435 8.85934 7.1094 8.92527 7.17375L12.822 10.9768C12.9407 11.0927 13 11.2342 13 11.4015C13 11.5689 12.9407 11.7104 12.822 11.8263C12.7033 11.9421 12.5648 12 12.4066 12C12.2484 12 12.1099 11.9421 11.9912 11.8263L8.50989 8.42857L5.02857 11.8263C4.90989 11.9421 4.76813 12 4.6033 12C4.43846 12 4.2967 11.9421 4.17802 11.8263C4.05934 11.7104 4 11.5721 4 11.4112C4 11.2503 4.05934 11.112 4.17802 10.9961L8.09451 7.17375C8.16044 7.1094 8.22637 7.06435 8.29231 7.03861C8.35824 7.01287 8.43077 7 8.50989 7Z' fill='%23151515'/%3E%3C/svg%3E");
    }
    .tui-select-box-placeholder {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 4.2rem;
      color: $black;
    }
    &.tui-select-box-disabled {
      border-color: $gray-35;
      background-color: $gray-15;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M8.49011 12C8.42418 12 8.35495 11.9871 8.28242 11.9614C8.20989 11.9356 8.14066 11.8906 8.07473 11.8263L4.17802 8.02317C4.05934 7.90734 4 7.76577 4 7.59846C4 7.43115 4.05934 7.28958 4.17802 7.17375C4.2967 7.05792 4.43516 7 4.59341 7C4.75165 7 4.89011 7.05792 5.00879 7.17375L8.49011 10.5714L11.9714 7.17375C12.0901 7.05792 12.2319 7 12.3967 7C12.5615 7 12.7033 7.05792 12.822 7.17375C12.9407 7.28958 13 7.42793 13 7.5888C13 7.74968 12.9407 7.88803 12.822 8.00386L8.90549 11.8263C8.83956 11.8906 8.77363 11.9356 8.70769 11.9614C8.64176 11.9871 8.56923 12 8.49011 12Z' fill='%23DBDBDB'/%3E%3C/svg%3E");
      .tui-select-box-placeholder {
        color: $gray-120;
      }
    }
  }
  .tui-select-box-dropdown {
    top: 4.1rem;
    background: $white;
    border: 0.1rem solid $gray-260;
    border-radius: 0.4rem;
    .tui-select-box-item {
      font-weight: normal;
      font-size: 1.4rem;
      line-height: 4rem;
      height: 4rem;
      color: $black;
      &.tui-select-box-highlight {
        background-color: $blue-200;
      }
    }
  }
  &.is-hover {
    .tui-select-box-input {
      border-color: $gray-260;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.49011 12C8.42418 12 8.35495 11.9871 8.28242 11.9614C8.20989 11.9356 8.14066 11.8906 8.07473 11.8263L4.17802 8.02317C4.05934 7.90734 4 7.76577 4 7.59846C4 7.43115 4.05934 7.28958 4.17802 7.17375C4.2967 7.05792 4.43516 7 4.59341 7C4.75165 7 4.89011 7.05792 5.00879 7.17375L8.49011 10.5714L11.9714 7.17375C12.0901 7.05792 12.2319 7 12.3967 7C12.5615 7 12.7033 7.05792 12.822 7.17375C12.9407 7.28958 13 7.42793 13 7.5888C13 7.74968 12.9407 7.88803 12.822 8.00386L8.90549 11.8263C8.83956 11.8906 8.77363 11.9356 8.70769 11.9614C8.64176 11.9871 8.56923 12 8.49011 12Z' fill='%23151515'/%3E%3C/svg%3E%0A");
    }
  }
  &.is-readonly {
    .tui-select-box-input {
      border-color: $gray-35;
      background-color: $gray-15;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M8.49011 12C8.42418 12 8.35495 11.9871 8.28242 11.9614C8.20989 11.9356 8.14066 11.8906 8.07473 11.8263L4.17802 8.02317C4.05934 7.90734 4 7.76577 4 7.59846C4 7.43115 4.05934 7.28958 4.17802 7.17375C4.2967 7.05792 4.43516 7 4.59341 7C4.75165 7 4.89011 7.05792 5.00879 7.17375L8.49011 10.5714L11.9714 7.17375C12.0901 7.05792 12.2319 7 12.3967 7C12.5615 7 12.7033 7.05792 12.822 7.17375C12.9407 7.28958 13 7.42793 13 7.5888C13 7.74968 12.9407 7.88803 12.822 8.00386L8.90549 11.8263C8.83956 11.8906 8.77363 11.9356 8.70769 11.9614C8.64176 11.9871 8.56923 12 8.49011 12Z' fill='%23DBDBDB'/%3E%3C/svg%3E");
      &.tui-select-box-disabled {
        .tui-select-box-placeholder {
          color: $black;
        }
      }
    }
  }
}
