// Display
.d-flex {
  @include d-flex();
}
.d-inline-flex {
  @include d-inline-flex();
}

// Wrap
.flex-wrap {
  @include flex-wrap();
}
.flex-nowrap {
  @include flex-nowrap();
}

// Justify content
.justify-content-start {
  @include justify-content-start();
}
.justify-content-end {
  @include justify-content-end();
}
.justify-content-center {
  @include justify-content-center();
}
.justify-content-between {
  @include justify-content-between();
}
.justify-content-around {
  @include justify-content-around();
}
.justify-content-evenly {
  @include justify-content-evenly();
}

// Align items
.align-items-start {
  @include align-items-start();
}
.align-items-end {
  @include align-items-end();
}
.align-items-center {
  @include align-items-center();
}
.align-items-baseline {
  @include align-items-baseline();
}
.align-items-stretch {
  @include align-items-stretch();
}

// Flex Center
.flex-center {
  @include flex-center();
}
.inline-flex-center {
  @include inline-flex-center();
}

// Flex grow
.flex-grow-1 {
  flex-grow: 1;
}
