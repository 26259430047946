//
// Generate Palettes
//
// Docs Colors
@each $color, $value in $docs-colors {
  .text-#{$color} {
    color: $value !important;
  }
  .bg-#{$color} {
    background-color: $value !important;
  }
  .border-#{$color} {
    border-color: $value !important;
  }
}

// Gray Scale Colors
@each $color, $value in $grays {
  .text-gray-#{$color} {
    color: $value !important;
  }
  .bg-gray-#{$color} {
    background-color: $value !important;
  }
  .border-gray-#{$color} {
    border-color: $value !important;
  }
}

// Blue Gray Scale Colors
@each $color, $value in $blue-grays {
  .text-blue-#{$color} {
    color: $value !important;
  }
  .bg-blue-#{$color} {
    background-color: $value !important;
  }
  .border-blue-#{$color} {
    border-color: $value !important;
  }
}

// Point Colors
@each $color, $value in $point-colors {
  .text-point-#{$color} {
    color: $value !important;
  }
  .bg-point-#{$color} {
    background-color: $value !important;
  }
  .border-point-#{$color} {
    border-color: $value !important;
  }
}

// Black(Opacity) Colors
@each $color, $value in $black-colors {
  .text-black-#{$color} {
    color: $value !important;
  }
  .bg-black-#{$color} {
    background-color: $value !important;
  }
  .border-black-#{$color} {
    border-color: $value !important;
  }
}

// White(Opacity) Colors
@each $color, $value in $white-colors {
  .text-white-#{$color} {
    color: $value !important;
  }
  .bg-white-#{$color} {
    background-color: $value !important;
  }
  .border-white-#{$color} {
    border-color: $value !important;
  }
}
