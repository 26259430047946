.btn,
.formbuilder-button .btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1rem;
  border-radius: 0.4rem;
  border-width: 0.1rem;
  border-style: solid;
  font-weight: $font-weight-midium;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: $white;
  transition: all 0.3s;
  vertical-align: middle;
  text-align: center;
  + .btn {
    margin-left: 0.8rem;
  }

  &.is-large {
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
  }

  //Primary Button
  &.is-primary,
  &.btn-default,
  &.btn-primary {
    background-color: $primary;
    border: 0.1rem solid $primary;
    + .is-outline {
      margin-left: 0.7rem;
    }
  }

  //Secondary Button
  &.is-outline {
    background-color: $white;
    border-color: $gray-260;
    font-weight: $font-weight-regular;
    font-size: 1.4rem;
    line-height: 2rem;
    color: $gray-280;
    &.is-large {
      font-size: 1.6rem;
      font-weight: $font-weight-midium;
    }
    .icon {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 0.2rem;
      &.is-plus {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.99'%3E%3Cpath d='M12.5 6L12.5 17.5' stroke='%23666666' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M6.5 11.5L18 11.5' stroke='%23666666' stroke-width='1.5' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E%0A");
      }
    }
  }

  //Small Button
  &.is-small {
    padding: 0.2rem 1.2rem;
    border-color: $gray-30;
    font-weight: $font-weight-regular;
    font-size: 1.3rem;
    line-height: 2.4rem;
    color: $gray-90;
  }

  //medium Button
  &.is-medium {
    padding-left: 2.8rem;
    padding-right: 2.8rem;
    background-color: $gray-10;
    border-color: $gray-65;
    color: $black;
    font-size: 1.4rem;
    font-weight: $font-weight-regular;
    line-height: 2rem;
  }

  //Download Button
  &.is-download {
    position: relative;
    margin: 0;
    padding: 0 0 0 2.9rem;
    border: 0;
    font-weight: $font-weight-regular;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: $black;
    &::before {
      position: absolute;
      left: 0;
      top: 0;
      width: 2.4rem;
      height: 2.4rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M1 12C1 14.1756 1.64514 16.3023 2.85383 18.1113C4.06253 19.9202 5.78049 21.3301 7.79048 22.1627C9.80047 22.9952 12.0122 23.2131 14.146 22.7886C16.2798 22.3642 18.2398 21.3165 19.7782 19.7782C21.3165 18.2398 22.3642 16.2798 22.7886 14.146C23.2131 12.0122 22.9952 9.80047 22.1627 7.79048C21.3301 5.78049 19.9202 4.06253 18.1113 2.85383C16.3023 1.64514 14.1756 1 12 1C9.08262 1 6.28472 2.15893 4.22182 4.22182C2.15893 6.28472 1 9.08262 1 12Z' fill='black'/%3E%3Cpath d='M17.3759 9.99931V15.4992C17.3723 15.766 17.3209 16.0294 17.2247 16.2743C17.1284 16.5192 16.9893 16.7409 16.8152 16.9267C16.6411 17.1125 16.4354 17.2587 16.2099 17.357C15.9844 17.4554 15.7435 17.5039 15.5009 17.4998H9.87595C9.63341 17.5039 9.39251 17.4554 9.16701 17.357C8.94151 17.2587 8.73583 17.1125 8.56171 16.9267C8.3876 16.7409 8.24846 16.5192 8.15224 16.2743C8.05602 16.0294 8.00461 15.766 8.00095 15.4992V6.56188C7.99954 6.55431 7.9997 6.54648 8.00142 6.53898C8.00313 6.53147 8.00636 6.52449 8.01086 6.51855C8.01537 6.51261 8.02103 6.50786 8.02742 6.50465C8.03382 6.50144 8.04079 6.49985 8.04782 6.50001H14.0947L17.3759 9.99931Z' stroke='white' stroke-linejoin='round'/%3E%3Cpath d='M13.9384 6.5V9.93742C14.0056 9.95054 14.0744 9.95054 14.1416 9.93742H17.3759' stroke='white' stroke-linejoin='round'/%3E%3Cpath d='M10.8134 14.4061H14.5634' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.8134 11.6561H12.6884' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      content: "";
    }
  }

  //Search Button
  &.is-search {
    padding: 0.5rem 1.5rem;
    background-color: $primary;
    color: $white;
    &[disabled] {
      background-color: $gray-35;
      .icon {
        &.is-search {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.22307 6.47296C4.73962 7.95641 4.73962 10.3616 6.22307 11.845C7.70652 13.3285 10.1117 13.3285 11.5951 11.845C13.0786 10.3616 13.0786 7.95641 11.5951 6.47296C10.1117 4.98951 7.70652 4.98951 6.22307 6.47296ZM5.23116 12.8369C3.19989 10.8057 3.19989 7.51232 5.23116 5.48105C7.26243 3.44979 10.5558 3.44978 12.587 5.48105C14.6183 7.51232 14.6183 10.8057 12.587 12.8369C10.5558 14.8682 7.26243 14.8682 5.23116 12.8369Z' fill='white'/%3E%3Cpath d='M11.7371 11.987L15.9797 16.2296' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E");
        }
      }
    }
    .icon {
      &.is-search {
        width: 2rem;
        height: 2rem;
        margin-right: 0.2rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.22307 6.47296C4.73962 7.95641 4.73962 10.3616 6.22307 11.845C7.70652 13.3285 10.1117 13.3285 11.5951 11.845C13.0786 10.3616 13.0786 7.95641 11.5951 6.47296C10.1117 4.98951 7.70652 4.98951 6.22307 6.47296ZM5.23116 12.8369C3.19989 10.8057 3.19989 7.51232 5.23116 5.48105C7.26243 3.44979 10.5558 3.44978 12.587 5.48105C14.6183 7.51232 14.6183 10.8057 12.587 12.8369C10.5558 14.8682 7.26243 14.8682 5.23116 12.8369Z' fill='white'/%3E%3Cpath d='M11.7371 11.987L15.9797 16.2296' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E");
      }
    }
  }
  &.is-search:hover,
  &.is-search.is-hover {
    background-color: $gray-280;
    .icon {
      &.is-search {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.22307 6.47296C4.73962 7.95641 4.73962 10.3616 6.22307 11.845C7.70652 13.3285 10.1117 13.3285 11.5951 11.845C13.0786 10.3616 13.0786 7.95641 11.5951 6.47296C10.1117 4.98951 7.70652 4.98951 6.22307 6.47296ZM5.23116 12.8369C3.19989 10.8057 3.19989 7.51232 5.23116 5.48105C7.26243 3.44979 10.5558 3.44978 12.587 5.48105C14.6183 7.51232 14.6183 10.8057 12.587 12.8369C10.5558 14.8682 7.26243 14.8682 5.23116 12.8369Z' fill='white'/%3E%3Cpath d='M11.7371 11.987L15.9797 16.2296' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E");
      }
    }
  }

  //arr Button
  &.is-arr {
    padding: 0;
    border: 0;
    font-weight: $font-weight-regular;
    font-size: 1.4rem;
    line-height: 2rem;
    color: $black;
    .icon {
      &.is-arr {
        width: 2rem;
        height: 2rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M7 16L12.6464 10.3536C12.8417 10.1583 12.8417 9.84171 12.6464 9.64645L7 4' stroke='black' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
      }
    }
  }

  //Icon Button_Single
  &.is-icon {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    border-color: $gray-260;
    .icon {
      margin: 0;
    }
  }

  .icon {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.4rem;
    &.is-search {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Ccircle cx='11' cy='11' r='7.25' stroke='%23666666' stroke-width='1.5'/%3E%3Cline x1='16.232' y1='16.1719' x2='20.8282' y2='20.7681' stroke='%23666666' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
    }
    &.is-sort {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M13.8873 12.0675L13.75 12.262V12.5V20C13.75 20.1381 13.6381 20.25 13.5 20.25H10.5C10.3619 20.25 10.25 20.1381 10.25 20V12.5V12.262L10.1127 12.0675L5.22568 5.14417C5.10878 4.97857 5.22722 4.75 5.42992 4.75H18.5701C18.7728 4.75 18.8912 4.97857 18.7743 5.14417L19.3536 5.55304L18.7743 5.14417L13.8873 12.0675Z' stroke='%23666666' stroke-width='1.5'/%3E%3Cpath d='M3.75 4.75H20.25' stroke='%23666666' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
    }
    &.is-link {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cg clip-path='url(%23clip0_72_6818)'%3E%3Cpath d='M4.91665 3.16667C3.95016 3.16667 3.16666 3.95017 3.16666 4.91667V13.0833C3.16666 14.0498 3.95016 14.8333 4.91665 14.8333H13.0833C14.0498 14.8333 14.8333 14.0498 14.8333 13.0833V10.4808C14.8333 10.1586 15.0944 9.89744 15.4166 9.89744C15.7388 9.89744 15.9999 10.1586 15.9999 10.4808V13.0833C15.9999 14.6942 14.6941 16 13.0833 16H4.91665C3.30583 16 2 14.6942 2 13.0833V4.91667C2 3.30584 3.30583 2 4.91665 2H7.5192C7.84137 2 8.10254 2.26117 8.10254 2.58333C8.10254 2.9055 7.84137 3.16667 7.5192 3.16667H4.91665ZM9.89746 2.58333C9.89746 2.26117 10.1586 2 10.4808 2H15.4167C15.7388 2 16 2.26117 16 2.58333V7.51923C16 7.8414 15.7388 8.10256 15.4167 8.10256C15.0945 8.10256 14.8333 7.8414 14.8333 7.51923V3.99163L10.8933 7.93171C10.6655 8.15952 10.2961 8.15952 10.0683 7.93171C9.84051 7.70391 9.84051 7.33456 10.0683 7.10676L14.0084 3.16667H10.4808C10.1586 3.16667 9.89746 2.9055 9.89746 2.58333Z' fill='%23666666'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_72_6818'%3E%3Crect width='18' height='18' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }
    &.is-qr {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M9.125 3H3.875C3.39175 3 3 3.39175 3 3.875V9.125C3 9.60825 3.39175 10 3.875 10H9.125C9.60825 10 10 9.60825 10 9.125V3.875C10 3.39175 9.60825 3 9.125 3Z' stroke='%23666666' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.125 14H3.875C3.39175 14 3 14.3918 3 14.875V20.125C3 20.6082 3.39175 21 3.875 21H9.125C9.60825 21 10 20.6082 10 20.125V14.875C10 14.3918 9.60825 14 9.125 14Z' stroke='%23666666' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.125 3H14.875C14.3918 3 14 3.39175 14 3.875V9.125C14 9.60825 14.3918 10 14.875 10H20.125C20.6082 10 21 9.60825 21 9.125V3.875C21 3.39175 20.6082 3 20.125 3Z' stroke='%23666666' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14 14V17' stroke='%23666666' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14 21H17V14' stroke='%23666666' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17 16H21' stroke='%23666666' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 20V21' stroke='%23666666' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
  }
  //close
  &.is-close {
    position: absolute;
    right: 0.9rem;
    top: 0.9rem;
    width: 2.2rem;
    height: 2.2rem;
    padding: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.63654 9.54513C8.88758 9.79616 9.29458 9.79616 9.54562 9.54513C9.79665 9.29409 9.79665 8.88709 9.54562 8.63605L5.90944 4.99988L9.54583 1.36349C9.79687 1.11246 9.79687 0.705451 9.54583 0.454417C9.2948 0.203383 8.88779 0.203384 8.63676 0.454418L5.00037 4.09081L1.36395 0.454389C1.11292 0.203355 0.705911 0.203355 0.454877 0.454389C0.203843 0.705423 0.203843 1.11243 0.454877 1.36346L4.09129 4.99988L0.455093 8.63608C0.204059 8.88712 0.204059 9.29412 0.455093 9.54516C0.706127 9.79619 1.11313 9.79619 1.36417 9.54516L5.00037 5.90895L8.63654 9.54513Z' fill='black'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-color: $white;
    border-radius: 50%;
  }
}

.moveTopBtn {
  cursor: pointer;
  display: block;
  position: fixed;
  right: 1.6rem;
  bottom: 1.6rem;
  width: 4rem;
  height: 4rem;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #e0e0e0;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.575638 11.4247C0.809953 11.659 1.18985 11.659 1.42417 11.4247L7.9999 4.84897L14.5756 11.4247C14.81 11.659 15.1899 11.659 15.4242 11.4247C15.6585 11.1904 15.6585 10.8105 15.4242 10.5762L8.77772 3.92973C8.34814 3.50015 7.65166 3.50015 7.22209 3.92973L0.575638 10.5762C0.341324 10.8105 0.341324 11.1904 0.575638 11.4247Z' fill='black'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  font-size: 0;
  opacity: 0;
  transition: opacity 0.3s;
  &.on {
    opacity: 1;
  }
}

.formBtnWrap {
  display: flex;
  .formControl {
    flex-grow: 1;
    margin-right: 0.8rem;
  }
}

.arrow__icon {
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 14L13 10L17 14' stroke='%23151515' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  height: 24px;
  width: 24px;
  margin-top: 2px;
  position: relative;
  transition: all 0.3s;
  &.expand {
    transform: rotate(180deg);
  }
}
