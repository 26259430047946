// Alignment
.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

// Underline
.text-underline {
  text-decoration: underline !important;
}

// Font-weight
@for $i from 1 through 9 {
  .fw-#{$i * 100} {
    font-weight: $i * 100 !important;
  }
}

//content title
.contentTitle {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: flex-end;
  min-height: 3.2rem;
  margin: 0 0 1rem;
  .contentTitle__titleTotal {
    font-weight: 400;
    margin-left: 1rem;
    font-size: 1.4rem;
  }
}
.contentTitle__left {
  display: flex;
  align-items: flex-end;
}
.contentTitle__right {
  display: flex;
  align-items: flex-end;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}
