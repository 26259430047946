.basic__table {
  width: 100%;
  border-top: 0.1rem solid $gray-40;
  border-bottom: 0.1rem solid $gray-40;
  border-left: 0.1rem solid $gray-30;
  border-right: 0.1rem solid $gray-30;
  text-align: center;
  th {
    height: 3.5rem;
    border-bottom: 0.1rem solid $gray-40;
    border-left: 0.1rem solid $gray-30;
    background-color: $gray-10;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2.8rem;
    color: $gray-80;
    &:last-child {
      border-right: 0.1rem solid $gray-30;
    }
  }
  td {
    text-align: center;
    height: 4rem;
    padding: 0.5rem 1rem;
    border-bottom: 0.1rem solid $gray-30;
    border-right: 0.1rem solid $gray-30;
  }
}
