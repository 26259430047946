.list {
  .list__item {
    border: 0.1rem solid #d0d0d0;
    border-radius: 0.8rem;
    background-color: $white;
    + .list__item {
      margin-top: 0.8rem;
    }
    .list__link {
      display: block;
      position: relative;
      padding: 1.6rem;
    }
    &.is-arr {
      .list__link {
        padding-right: 5.5rem;
        &::before {
          position: absolute;
          right: 1.6rem;
          top: 50%;
          width: 2rem;
          height: 2rem;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M7 16L12.6464 10.3536C12.8417 10.1583 12.8417 9.84171 12.6464 9.64645L7 4' stroke='black' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
          transform: translateY(-50%);
          content: "";
        }
      }
    }
    .list__label {
      display: block;
      overflow: hidden;
      margin-bottom: 0.6rem;
      font-weight: $font-weight-midium;
      font-size: 1.4rem;
      line-height: 2rem;
      color: $primary;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .list__title {
      display: block;
      overflow: hidden;
      max-height: 2.2rem;
      margin: 0 0 0.6rem;
      color: $primary;
      font-weight: $font-weight-midium;
      font-size: 1.6rem;
      line-height: 2.2rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.is-new {
        span {
          display: inline-block;
          overflow: hidden;
          position: relative;
          max-width: calc(100% - 2.2rem);
          padding-right: 2.2rem;
          font-weight: $font-weight-midium;
          font-size: 1.6rem;
          line-height: 2rem;
          color: $primary;
          white-space: nowrap;
          text-overflow: ellipsis;
          &:after {
            position: absolute;
            right: 0;
            top: 0.2rem;
            width: 1.6rem;
            height: 1.6rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Crect width='16' height='16' rx='2' fill='%23F0F0F0'/%3E%3Cpath d='M12.4 3H10.048V8.824H9.992L6.016 3H4V12.912H6.324V7.088H6.38L10.412 12.912H12.4V3Z' fill='%23BD0000'/%3E%3C/svg%3E");
            content: "";
          }
        }
      }
    }
    p {
      overflow: hidden;
      font-weight: $font-weight-regular;
      font-size: 1.4rem;
      line-height: 2rem;
      color: $gray-260;
      white-space: nowrap;
      text-overflow: ellipsis;
      span {
        + span {
          margin-left: 0.6rem;
        }
      }
    }
    .list__btnWrap {
      display: flex;
      flex-wrap: wrap;
      position: absolute;
      right: 1.6rem;
      top: 1.6rem;
      button {
        width: 2rem;
        height: 2rem;
        font-size: 0;
        + button {
          margin-left: 2rem;
        }
        &.is-modify {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cg opacity='0.99'%3E%3Cpath d='M7.10017 16.5397L2.5 17.5001L3.46238 12.9095L13.8968 2.42544C14.1379 2.18428 14.4244 1.9931 14.7399 1.86289C15.0554 1.73268 15.3935 1.66602 15.7349 1.66675C16.424 1.66675 17.085 1.93994 17.5723 2.42622C18.0596 2.9125 18.3333 3.57203 18.3333 4.25973C18.3341 4.6004 18.2673 4.93785 18.1368 5.25266C18.0063 5.56746 17.8147 5.85341 17.573 6.09403L7.10017 16.5397Z' stroke='black' stroke-width='1.2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1.29199 17.9167H18.7087' stroke='black' stroke-width='1.2' stroke-miterlimit='10' stroke-linecap='round'/%3E%3Cpath d='M16.3669 6.81663L13.1836 3.6333' stroke='black' stroke-width='1.2' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E");
        }
        &.is-delete {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cg opacity='0.99'%3E%3Cpath d='M14.0666 18.5418H5.9333C5.52742 18.5423 5.13683 18.387 4.84218 18.1079C4.54753 17.8287 4.37133 17.4471 4.34997 17.0418L3.6333 4.2251H16.3666L15.65 17.0418C15.6286 17.4471 15.4524 17.8287 15.1578 18.1079C14.8631 18.387 14.4725 18.5423 14.0666 18.5418Z' stroke='black' stroke-width='1.2' stroke-miterlimit='10' stroke-linecap='round'/%3E%3Cpath d='M2.0415 4.2251H17.9582' stroke='black' stroke-width='1.2' stroke-miterlimit='10' stroke-linecap='round'/%3E%3Cpath d='M8.40807 1.04175H11.5914C12.0135 1.04175 12.4184 1.20944 12.7169 1.50794C13.0154 1.80643 13.1831 2.21128 13.1831 2.63341V4.22508H6.81641V2.63341C6.81641 2.21128 6.9841 1.80643 7.28259 1.50794C7.58109 1.20944 7.98594 1.04175 8.40807 1.04175Z' stroke='black' stroke-width='1.2' stroke-miterlimit='10' stroke-linecap='round'/%3E%3Cpath d='M10 7.5V15.5' stroke='black' stroke-width='1.2' stroke-miterlimit='10' stroke-linecap='round'/%3E%3Cpath d='M13 8V15' stroke='black' stroke-width='1.2' stroke-miterlimit='10' stroke-linecap='round'/%3E%3Cpath d='M7 8V15' stroke='black' stroke-width='1.2' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E");
        }
      }
    }
    .list__box {
      display: flex;
      justify-content: space-around;
      background-color: $gray-20;
      margin-top: 0.8rem;
      padding: 1.2rem 0.8rem;
      border-radius: 0.8rem;
      li {
        min-width: 6rem;
        font-weight: $font-weight-regular;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: $gray-85;
        text-align: center;
        span {
          display: block;
          margin-top: 0.5rem;
          color: $black;
          font-weight: $font-weight-semibold;
          &.is-point {
            color: $point-3;
          }
        }
      }
    }
  }
}

.listView {
  padding: 1.6rem;
  border: 0.1rem solid #d0d0d0;
  border-radius: 0.8rem;
  background-color: $white;
  + .listView {
    margin-top: 0.8rem;
  }
  .listView__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    strong {
      display: block;
      font-weight: $font-weight-semibold;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $primary;
    }
    p {
      font-weight: $font-weight-regular;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: $black;
      span {
        font-weight: $font-weight-semibold;
        color: $point-3;
      }
    }
  }
}

.barList {
  display: flex;
  flex-wrap: wrap;
  li {
    font-weight: $font-weight-regular;
    font-size: 1.4rem;
    line-height: 2rem;
    color: $gray-85;
    + li {
      position: relative;
      margin-left: 1rem;
      padding-left: 1.1rem;
      &::before {
        position: absolute;
        left: 0;
        top: 50%;
        width: 0.1rem;
        height: 1rem;
        background-color: $gray-40;
        transform: translateY(-50%);
        content: "";
      }
    }
  }
}

.textList {
  margin: -2rem 0;
  li {
    padding: 2rem 0;
    & + li {
      border-top: 0.1rem solid $gray-30;
    }
    b {
      display: block;
      margin-bottom: 0.6rem;
      font-weight: $font-weight-midium;
      font-size: 1.4rem;
      line-height: 2rem;
      color: $primary;
    }
    p {
      overflow: hidden;
      position: relative;
      font-weight: $font-weight-midium;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $primary;
      white-space: nowrap;
      text-overflow: ellipsis;
      a {
        color: $primary;
      }
    }
    em {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 1.6rem;
      font-weight: $font-weight-midium;
      line-height: 2rem;
      color: $point-1;
    }
  }
  &.is-short {
    li {
      position: relative;
      padding-left: 10rem;
      b {
        position: absolute;
        left: 0;
        top: 50%;
        min-width: 10rem;
        font-weight: $font-weight-regular;
        font-size: 1.6rem;
        line-height: 2rem;
        color: $gray-85;
        transform: translateY(-50%);
      }
      p {
        font-weight: $font-weight-midium;
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }
}
.textList2 {
  margin: -1.6rem 0;
  li {
    position: relative;
    padding: 1.6rem 0;
    padding-left: 10rem;
    font-weight: $font-weight-midium;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $black;
    word-break: keep-all;
    & + li {
      border-top: 0.1rem solid $gray-30;
    }
    b {
      position: absolute;
      left: 0;
      top: 1.6rem;
      max-width: 10rem;
      font-weight: $font-weight-regular;
      font-size: 1.6rem;
      line-height: 2rem;
      color: $gray-85;
    }
  }
}

.code__list {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 18px;
  border-top: 1px solid #f1f1f1;

  &:first-child {
    border-top: 0;
    padding-top: 28px;
  }

  &:last-child {
    border-bottom: 1px solid #f1f1f1;
  }

  .code__list-processing {
    display: inline-block;
    width: 65px;
    border-radius: 3px;
    padding: 5px 0;
    text-align: center;
    background-color: rgba(0, 169, 255, 0.08);
    color: #00a9ff;
  }

  .code__list-content {
    max-height: 53px;
    padding-top: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    word-break: break-all;
    -webkit-line-clamp: 2;
    white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    color: $black;
  }

  a + .code__list-bottom {
    padding-top: 8px;
  }

  .code__list-bottom {
    color: $gray-80;
    padding-top: 2px;
    display: flex;

    span:first-child {
      min-width: 49px;
      display: inline-block;
      margin-right: 18px;
    }
  }
  .code__list-bottom2 {
    color: $gray-80;
    padding-top: 8px;
    > :first-child {
      padding-bottom: 2px;
    }
    // 요청일시
    > :nth-child(2) {
      padding: 0 0 2px 8px;
      margin-left: 8px;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='2' height='11' viewBox='0 0 2 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='1' height='10' fill='%23D0D0D0'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: 0 3px;
    }
  }

  .is-confirm,
  .is-approval {
    display: flex;

    .btn {
      height: 32px;
      font-size: 14px;
      font-weight: 400;
      margin-left: 32px;
      min-width: 49px;
      position: relative;
      top: 12px;
    }
  }

  .is-confirm .btn {
    color: #333333;
    border-color: #666666;
  }
}

.satisfaction__list {
  border: 1px solid $gray-50;
  border-radius: 12px;
  margin-bottom: 25px;

  &:nth-last-child(2) {
    margin-bottom: 0;
  }

  .satisfaction__list__top {
    padding: 16px;
    background-color: #fafbfb;
    border-bottom: 1px solid $gray-15;
    border-radius: 12px 12px 0px 0px;

    > .request__card {
      background-color: #fafbfb;
      padding: 0;

      > :last-child {
        padding-bottom: 12px;
      }
    }

    .satisfaction__list__content {
      max-height: 53px;
      padding-top: 10px;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      word-break: break-all;
      -webkit-line-clamp: 2;
      white-space: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      color: $black;
    }
  }

  .satisfaction__list__bottom {
    display: flex;
    flex-direction: column;
    padding: 28px 16px 24px 16px;

    .radioGroup {
      min-width: 33%;
      margin-bottom: 16px;
      flex: 1;
    }

    .radioGroup + .radioGroup {
      margin-left: auto;
    }

    .btn {
      height: 42px;
      font-size: 15px;
    }
  }
}

div.chart__block ~ div > .no__list {
  height: auto;
}

.no__list {
  display: flex;
  align-items: center;
  height: 50vh;
  min-height: 20rem;
  color: #666666;
  text-align: center;
  font-size: 1.6rem;
  flex-direction: column;
  justify-content: center;
}

.search__list {
  padding: 32px 16px 40px 16px;

  .search__list-title {
    margin-bottom: 19.5px;
    display: flex;
    align-items: center;

    h2 {
      color: $primary;
      padding-right: 4px;
    }

    span {
      color: $black;
      font-size: 15px;
    }

    & + .code__list {
      border-top: 1px solid #f1f1f1;
      padding-top: 28px;
    }
  }

  > :nth-last-child(2) {
    padding-bottom: 0;
  }

  > .btn {
    border: 1px solid $gray-160;
    color: $gray-280;
    font-size: 14px;
    font-weight: 400;
    margin-top: 32px;
    height: 42px;
    width: 100%;
  }
}
