@for $i from 0 through 100 {
  .m-#{$i} {
    margin: $i * 0.1 + rem !important;
  }
  .mt-#{$i} {
    margin-top: $i * 0.1 + rem !important;
  }
  .mb-#{$i} {
    margin-bottom: $i * 0.1 + rem !important;
  }
  .ml-#{$i} {
    margin-left: $i * 0.1 + rem !important;
  }
  .mr-#{$i} {
    margin-right: $i * 0.1 + rem !important;
  }
}
